<template>
  <div class="layout--full-page">
    <div
      class="
        h-screen
        flex
        w-full
        bg-img
        vx-row
        no-gutter
        items-center
        justify-center
      "
      id="page-login"
    >
      <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-3/5 sm:m-0 m-4">
        <vx-card>
          <div slot="no-body">
            <div
              class="
                vx-row
                no-gutter
                justify-center
                items-center
                full-page-bg-color
              "
            >
              <div class="vx-col hidden lg:block lg:w-1/2">
                <img
                  src="@/assets/images/pages/forgot-password.png"
                  alt="login"
                  class="mx-auto"
                />
              </div>

              <div class="vx-col sm:w-full md:w-full lg:w-1/2 d-theme-dark-bg">
                <div class="p-8 login-tabs-container">
                  <div class="vx-card__title mb-4">
                    <h4 class="mb-4">{{ $t("main.Recover_your_password") }}</h4>
                    <p>
                      {{
                        $t(
                          "main.Please_enter_your_email_address_and_we_will_send_you_a_link_to_reset_your_password"
                        )
                      }}
                    </p>
                  </div>
                  <vs-tabs>
                    <vs-tab :label="$t('main.enter_your_email')">
                      <form @keyup.enter="recover">
                      <div class="vx-col w-full mb-4">
                        <vs-input
                          v-validate="'required|email'"
                          name="email"
                          type="email"
                          :label-placeholder="$t('main.email')"
                          v-model="email"
                          class="w-full mb-4"
                        />
                        <span class="text-danger text-sm">{{ errors.first("email") | text_formatter }}</span>
                      </div>

                        <vs-button
                          type="border"
                          :to="{ name: 'login' }"
                          class="px-4 w-full md:w-auto"
                          >{{ $t("main.Back_To_Login") }}</vs-button
                        >
                        <vs-button
                          @click="recover"
                          :disabled="disabled"
                          class="
                            float-right
                            px-4
                            w-full
                            md:w-auto
                            mt-3
                            mb-8
                            md:mt-0
                            md:mb-0
                          "
                          >{{ $t("main.Recover_Password") }}</vs-button
                        >
                      </form>
                    </vs-tab>
                  </vs-tabs>
                </div>
              </div>
            </div>
          </div>
        </vx-card>
      </div>
    </div>
  </div>
</template>

<script>
import {RepositoryFactory} from '@/Repositories/RepositoryFactory'
const ForgetPasswordRepository = RepositoryFactory.get('AuthRepository', 'ForgetPasswordRepository')

export default {
  data () {
    return {
      email: '',
      disabled: false,
      emailError: false,

    }
  },
  computed: {

  },
  methods: {
    recover () {
      this.disabled = true;
        let type = this.$route.query.type;

        this.$validator
        .validate()
        .then((valid) => {
          if (valid) {
            ForgetPasswordRepository
            .forgetPassword({email:this.email}, type)
            .then(() => {
                this.successMsg(
                  this.$t('auth.Please_check_your_mail_inbox'),
                  this.$t('auth.recover_success')
                )
                this.$router.push({name:'login'})
                this.disabled = false;
              },
              (error) => {

                this.errMsg(error);
                this.disabled = false;
              })
            .catch(err => {
              this.errMsg(err)
              this.disabled = false;
            })
          } else {
            this.errMsg();
            this.disabled = false;
          }


        })
        .catch((err) => this.errMsg(err))

    }
  }
}
</script>

<style lang="scss">
.login-tabs-container {
  min-height: fit-content;
  min-height: -moz-fit-content;

  .con-tab {
    padding-bottom: 14px;
  }

  .con-slot-tabs {
    margin-top: 1rem;
  }
}
</style>
